
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { globalFilterActions } from "../../../store/slices/globalFilters";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import switchrole from "../../../assets/images/icons/switch-role-icon.svg";
import { Button } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { userAuthoritiesActions } from "../../../store/slices/userRoles";
import { useNavigate } from "react-router-dom";

type Props = {};

const RolesGlobalFilter = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUserRole = useSelector((state: any) => state.globalFilters.currentUserRole);
  const currentRolesList = useSelector((state: any) => state.userInfo);

  const [selectedValue, setSelectedValue] = useState<string>("");
  const [dashboardRoles, setDashboardRoles] = useState<any[]>([]);

  const allRoles = useMemo(() => {
    const roles = localStorage.getItem("allRoles");
    return roles ? JSON.parse(roles) : {};
  }, []);

  const allRolesAuthorities = useMemo(() => {
    const authorities = localStorage.getItem("allRolesAuthorities");
    return authorities ? JSON.parse(authorities) : {};
  }, []);

  const instituteId = localStorage.getItem("institute");

  // Set the default role and update dashboard roles
  useEffect(() => {
    if (!allRoles[instituteId]) return;

    const filteredRoles = allRoles[instituteId].filter(
      (el: any) =>
        ["student", "teacher", "editingteacher", "instituteadmin", "departmenthead", "programhead", "admin"].includes(
          el.shortName
        )
    );

    if (JSON.stringify(dashboardRoles) !== JSON.stringify(filteredRoles)) {
      setDashboardRoles(filteredRoles);
    }

    if (!selectedValue && filteredRoles.length > 0) {
      const priorityOrder = [
        "admin",
        "instituteadmin",
        "departmenthead",
        "programhead",
        "editingteacher",
        "teacher",
        "student",
      ];
      const highestPriorityRole = priorityOrder
        .map((roleName) => filteredRoles.find((role: { shortName: string }) => role.shortName === roleName))
        .find((role) => role);

      if (highestPriorityRole) {
        setSelectedValue(highestPriorityRole.id);

        // Update Redux store and localStorage
        dispatch(
          globalFilterActions.currentUserRole({
            id: highestPriorityRole.id,
            shortName: highestPriorityRole.shortName,
            rolePriority: highestPriorityRole.rolePriority,
          })
        );
        dispatch(userAuthoritiesActions.updateUserAuthorities(allRolesAuthorities[highestPriorityRole.id]));

        localStorage.setItem(
          "currentUserRole",
          JSON.stringify({
            id: highestPriorityRole.id,
            shortName: highestPriorityRole.shortName,
            rolePriority: highestPriorityRole.rolePriority,
          })
        );
        localStorage.setItem("userAuthorities", JSON.stringify(allRolesAuthorities[highestPriorityRole.id]));
      }
    }
  }, [allRoles, allRolesAuthorities, instituteId, selectedValue, dashboardRoles, dispatch]);

  const getCurrentValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const roleShortName = selectedOption.dataset.name || "";
    const rolePriority = selectedOption.dataset.priority || "";
    const selectedRoleId = e.target.value;

    if (selectedRoleId !== selectedValue) {
      setSelectedValue(selectedRoleId);

      // Update Redux and localStorage
      dispatch(
        globalFilterActions.currentUserRole({
          id: selectedRoleId,
          shortName: roleShortName,
          rolePriority,
        })
      );
      dispatch(userAuthoritiesActions.updateUserAuthorities(allRolesAuthorities[selectedRoleId]));
      localStorage.setItem(
        "currentUserRole",
        JSON.stringify({ id: selectedRoleId, shortName: roleShortName, rolePriority })
      );
      localStorage.setItem("userAuthorities", JSON.stringify(allRolesAuthorities[selectedRoleId]));

      // Redirect based on role
      pageRedirectFn(roleShortName);
    }
  };

  const pageRedirectFn = (roleShortName: string) => {
    if (["student", "teacher", "editingteacher"].includes(roleShortName)) {
      navigate("/dashboard");
    } else {
      navigate("/performance");
    }
  };

  return (
    <>
      {dashboardRoles.length > 1 && (
        <div className="switch-role">
          <OverlayTrigger
            trigger={["click"]}
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">{t("role.switchRole")}</Popover.Header>
                <Popover.Body>
                  <select className="form-select" value={selectedValue} onChange={getCurrentValue}>
                    {dashboardRoles.map((role: any, index: number) => (
                      <option
                        key={index}
                        value={role.id}
                        data-name={role.shortName}
                        data-priority={role.rolePriority}
                      >
                        {role.name}
                      </option>
                    ))}
                  </select>
                </Popover.Body>
              </Popover>
            }
            rootClose
          >
            <Button variant="link" className="head-icon my-role-element">
              <img src={switchrole} alt="Switch Role" />
            </Button>
          </OverlayTrigger>
          <Tooltip
            anchorSelect=".my-role-element"
            content={t("role.switchRole")}
            place="bottom"
            style={{ zIndex: 2 }}
          />
        </div>
      )}
    </>
  );
};

export default RolesGlobalFilter;

	