import React, { useEffect } from "react";
import { Alert, AlertHeading } from "react-bootstrap";
import { MdError, MdClose } from "react-icons/md";
interface ITimerAlertBox {
  alertMsg: string;
  variant: string;
  setShowAlert: (params: boolean) => void;
  showAlert: boolean;
  className?: string;
  status?: number;
}

const TimerAlertBox: React.FunctionComponent<ITimerAlertBox> = ({
  alertMsg,
  variant,
  setShowAlert,
  showAlert,
  status,
}: ITimerAlertBox) => {
  useEffect(() => {}, [showAlert]);

  return (
    <React.Fragment>
      <div>
        {showAlert && (
          <Alert
            style={{
              position: "relative",
              // paddingRight: "35px",
              padding: "15px 20px",
              borderLeft: "5px solid #58151c",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            }}
            variant={variant}
            // dismissible
          >
            <button
              style={{
                position: "absolute",
                top: "4px",
                right: "4px",
                background: "#58151c",
                border: "2px solid #58151c",
                borderRadius: "50%",
                cursor: "pointer",
                color: "white",
                fontSize: "14px",
                width: "28px",
                height: "28px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 4px 6px, rgba(0, 0, 0, 0.1) 0px 1px 3px",
              }}
              onClick={() => setShowAlert(false)}
              aria-label="Close"
            >
              <MdClose size={16} />
            </button>
            <MdError size={22} /> <b>{`${status ? status + ": " : ""}`}</b>
            {alertMsg}
          </Alert>
        )}
      </div>
    </React.Fragment>
  );
};

export default TimerAlertBox;
