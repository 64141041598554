import React from 'react'
import { Route } from 'react-router-dom'

const HostelManagement = React.lazy(() => import('../../../pages/hostelManagement'))
const ManageHostel = React.lazy(() => import('../../../pages/hostelManagement/manageHostel'))
const ManageRooms = React.lazy(() => import('../../../pages/hostelManagement/manageRooms'))
const AddFacilitiesHostal = React.lazy(() => import('../../../pages/hostelManagement/manageHostel/addFacilities'))
const ManageFacility = React.lazy(() => import('../../../pages/hostelManagement/manageFacility'))
const RoomAllocation = React.lazy(() => import('../../../pages/hostelManagement/roomAllocation'))
const BookingRequest = React.lazy(() => import('../../../pages/hostelManagement/bookingRequest'))
const ServiceRequest = React.lazy(() => import('../../../pages/hostelManagement/serviceRequest'))
const VisitorRequest = React.lazy(() => import('../../../pages/hostelManagement/visitorRequest'))
const MessMenu = React.lazy(() => import('../../../pages/hostelManagement/messMenu'))
const DiscountFacility = React.lazy(()=> import('../../../pages/hostelManagement/manageRooms/discount'))
const AssignRoom = React.lazy(()=> import('../../../pages/hostelManagement/roomAllocation/assignRoom'))
const RequestType = React.lazy(()=> import('../../../pages/hostelManagement/serviceRequest/requesType'))
const StudentHostelDashboard = React.lazy(()=> import('../../../pages/hostelManagement/studentHostelDashboard'))
const StudentServiceRequest = React.lazy(()=> import('../../../pages/hostelManagement/studentHostelDashboard/studentServiceRequest'))
const StudentBookingRequest = React.lazy(()=> import('../../../pages/hostelManagement/studentHostelDashboard/studentBookingRequest'))
const Preference = React.lazy(()=> import('../../../pages/hostelManagement/bookingRequest/preferences'))
const PreferenceType = React.lazy(()=> import('../../../pages/hostelManagement/bookingRequest/preferences/preferenceType'))
const StudentVisitorRequest = React.lazy(()=> import('../../../pages/hostelManagement/studentHostelDashboard/studentVisitorRequest'))

const HostelManagementRoute = () => {
  return [
    <Route key='hostelmanagement' path='/hostelmanagement' element={<HostelManagement />} />,
    <Route key='managehostel' path='/managehostel' element={<ManageHostel />} />,
    <Route key='managerooms' path="/managerooms/:selectedHostelId?" element= {<ManageRooms/>} />,
    <Route key='addfacilities' path='/addfacilities/:id' element={<AddFacilitiesHostal />} />,
    <Route key='managefacility' path='/managefacility' element={<ManageFacility />} />,
    <Route key='roomallocation' path='/roomallocation' element={<RoomAllocation />} />,
    <Route key='bookingrequest' path='/bookingrequest/:bookingReqStatus?' element={<BookingRequest />} />,
    <Route key='preferences' path='/preferences' element={<Preference />} />,
    <Route key='preferencetype' path='/preferencetype' element={<PreferenceType />} />,
    <Route key='servicerequest' path='/servicerequest/:selectedReqStatus?' element={<ServiceRequest />} />,
    <Route key='visitorrequest' path='/visitorrequest' element={<VisitorRequest />} />,
    <Route key='messmenu' path='/messmenu' element={<MessMenu />} />,
    <Route key='discountfacility' path='/discountfacility' element={<DiscountFacility />} />,
    <Route key='assignroom' path='/assignroom/:id?' element={<AssignRoom />} />,
    <Route key='requesttype' path='/requesttype' element={<RequestType />} />,
    <Route key='studenthosteldashboard' path='/studenthosteldashboard' element={<StudentHostelDashboard />} />,
    <Route key='studentservicerequest' path='/studentservicerequest' element={<StudentServiceRequest />} />,
    <Route key='studentbookingrequest' path='/studentbookingrequest' element={<StudentBookingRequest />} />,
    <Route key='studentvisitorrequest' path='/studentvisitorrequest' element={<StudentVisitorRequest />} />,
  ]
}

export default HostelManagementRoute

